import DatePicker from 'react-datepicker'
import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import compact from 'lodash/compact'
import flatten from 'lodash/flatten'
import moment from 'moment'
import sumBy from 'lodash/sumBy'
import uniq from 'lodash/uniq'
import uniqBy from 'lodash/uniqBy'
import { Button, Spinner } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'

import 'react-datepicker/dist/react-datepicker.css'

import CompletedTable from '../components/earnings/table'
import Layout from '../components/layouts/Layout'
import MainBox from '../components/common/MainBox'
import { QueryName } from '../hasura/queryNames'
import { Role } from '../interfaces/Role'
import { fetchVetsAction, usersSelector, UsersState } from '../hasura/slices/users'
import { isVocn } from '../lib/enterpriseHelpers'
import { skuForConsultation } from '../lib/pricingHelpers'

import {
  distributionEmailsSelector,
  DistributionEmailsState,
  fetchDistributionEmailsAction,
} from '../hasura/slices/distribution-emails'

import {
  Option,
  consultationDescription,
  downloadCsv,
  isAdmin,
  isVet,
  niceDate,
  nullOption,
  searchQueryParams,
  vetImage,
} from '../lib/helpers'

import {
  ConsultationsState,
  consultationsSelector,
  fetchCompletedConsultationsAction,
  unsetCompletedConsultationsAction,
  updateMultiplePaidAction,
} from '../hasura/slices/consultations'

export default function CompletedConsultations() {
  const dispatch = useDispatch()

  const { accessToken, user, role, vets, vetsExpanded }: UsersState = useSelector(usersSelector)
  const { completedConsultations, isQuerying }: ConsultationsState = useSelector(consultationsSelector)
  const { distributionEmails }: DistributionEmailsState = useSelector(distributionEmailsSelector)

  const [startDate, setStartDate] = useState(moment().startOf('month').toDate())
  const [endDate, setEndDate] = useState(moment().toDate())
  const [vetOptions, setVetOptions] = useState<Option[] | undefined>([])
  const [selectedVet, setSelectedVet] = useState<Option | null>(null)
  const [selectedEnterprise, setSelectedEnterprise] = useState<Option | null>(null)

  const vetRoleEnterprises = compact(user?.user_roles.filter((u) => u.role === Role.Vet).map((r) => r.enterprise))
  const overflowEnterprises = compact(completedConsultations.map((c) => c.case.dicom_server?.organization?.enterprise))
  const vetEnterprises = uniqBy(vetRoleEnterprises.concat(...overflowEnterprises), 'id')

  const isVetForMultipleEnterprises = isVet(role) && vetEnterprises.length > 1
  const enterpriseOptions: Option[] = vetEnterprises.map((e) => ({ value: e.id, label: e.short_name, image: e.logo_aws_s3_url }))

  const completedConsultationsFiltered = selectedEnterprise
    ? completedConsultations.filter((c) =>
        [c.completed_by_enterprise_id, c.case.dicom_server?.organization?.enterprise.id].includes(selectedEnterprise.value)
      )
    : completedConsultations

  /*
    Effects 
  */

  useEffect(() => {
    const distributionEmailIds = uniq(
      compact(flatten(completedConsultationsFiltered.map((c) => c.distribution_email_ids_denormalized?.split(','))))
    ).map((id) => parseInt(id))
    if (!distributionEmailIds.length || !isAdmin(role)) return

    dispatch(fetchDistributionEmailsAction(accessToken, distributionEmailIds))
  }, [completedConsultations])

  useEffect(() => {
    if (!vets) return

    const options = vets.map((v): Option => ({ value: v.id, label: v.display_name }))
    const me = isVet(role) && options.find((o) => o.value === user?.id)
    if (me) setSelectedVet(me)
    setVetOptions([nullOption('All radiologists')].concat(...options))
  }, [vets])

  useEffect(() => {
    if (!startDate || !endDate || !accessToken || !user) return

    fetchCompletedConsultations()
    return () => {
      dispatch(unsetCompletedConsultationsAction())
    }
  }, [accessToken, startDate, endDate, selectedVet, user])

  useEffect(() => {
    if (!user || !accessToken || !role) return

    dispatch(fetchVetsAction(accessToken, user.organization.enterprise.id))
  }, [accessToken, user, role])

  useEffect(() => {
    const time = searchQueryParams('t')
    if (time === 'this-month') {
      setThisMonth()
    } else if (time === 'this-week') {
      setThisWeek()
    } else if (time === 'today') {
      setToday()
    }
  }, [])

  /*
    Methods
  */

  const fetchCompletedConsultations = () => {
    if (!user) return

    // Quickfix for issue with timezones and the completed table...
    // Since hasura is in UTC, but most vets are in EST, if they complete a case after 8PM
    // it won't show up in their completed table until the next day. This can prevent the vet from
    // completing workflows like adding an addendum.
    const endDateIsToday = moment(endDate).isSame(new Date(), 'day')
    const endDateString = moment(endDate)
      .add(endDateIsToday ? 2 : 1, 'day')
      .toDate()
      .toLocaleDateString('en-US')

    const enterpriseIds = isAdmin(role) ? [user.organization.enterprise.id] : vetEnterprises.map((e) => e.id)

    dispatch(
      fetchCompletedConsultationsAction(
        accessToken,
        enterpriseIds,
        startDate.toLocaleDateString('en-US'),
        endDateString,
        selectedVet?.value || undefined
      )
    )
  }

  const handleDownload = () => {
    const filename = `Radimal-${selectedVet ? `${selectedVet?.label}-` : ''}${startDate.toLocaleDateString(
      'en-US'
    )} to ${endDate.toLocaleDateString('en-US')}.csv`

    const displaySkus = isVocn(user)

    const headers = `Date,Radiologist,Paid,Amount,Practice,Description,SKU,Charge Notes,Charge Amount`

    const rows = [headers].concat(
      ...completedConsultationsFiltered.map((c) =>
        flatten([
          niceDate(c.completed_at),
          c.receiving_vet?.display_name.replace(/,/g, ''),
          c.receiving_vet_paid ? 'yes' : 'no',
          c.receiving_vet_pay_amount,
          c.case.dicom_server?.organization?.display_name,
          consultationDescription(c, false).replace(/,/g, ''),
          displaySkus ? skuForConsultation(c) : undefined,
          c.charge_adjustment_notes?.replace(/,/g, ''),
          c.price_amount,
        ]).join(',')
      )
    )
    downloadCsv(filename, rows)
  }

  const setToday = () => {
    setStartDate(moment().toDate())
    setEndDate(moment().toDate())
  }

  const setThisMonth = () => {
    setStartDate(moment().startOf('month').toDate())
    setEndDate(moment().toDate())
  }

  const setThisWeek = () => {
    setStartDate(moment().startOf('week').toDate())
    setEndDate(moment().toDate())
  }

  const setLastWeek = () => {
    setStartDate(moment().startOf('week').subtract(1, 'week').toDate())
    setEndDate(moment().startOf('week').toDate())
  }

  const handleMarkAllAsPaid = async () => {
    const ids = completedConsultations.map((c) => c.id)
    await dispatch(updateMultiplePaidAction(accessToken, ids))
    fetchCompletedConsultations()
  }

  return (
    <Layout>
      <MainBox defaultPadding>
        <h4 className="bold">Earnings</h4>

        <div className="my-3">
          <div className="my-1 d-flex align-items-center width-fit-content">
            <DatePicker
              dateFormat="MM/dd/yy"
              filterDate={(date: any) => moment() > date}
              className="react-select-styles"
              selected={startDate}
              onChange={(date: any) => setStartDate(date)}
            />

            <p className="m-0 mx-2">to</p>

            <DatePicker
              dateFormat="MM/dd/yy"
              filterDate={(date: any) => moment() > date}
              className="react-select-styles"
              selected={endDate}
              onChange={(date: any) => setEndDate(date)}
            />

            <Button onClick={setThisWeek} color="primary" outline size="sm" style={{ minWidth: '125px' }} className="ml-2">
              🗓️ This Week
            </Button>

            <Button onClick={setLastWeek} color="primary" outline size="sm" style={{ minWidth: '125px' }} className="ml-2">
              🗓️ Last Week
            </Button>
          </div>

          <Select
            classNamePrefix="react-select"
            className="mt-2 max-width-350px w-100"
            placeholder="Search specialist..."
            onChange={(o: Option | null) => setSelectedVet(o)}
            options={vetOptions}
            value={selectedVet}
            formatOptionLabel={(option: any) => {
              const imageSrc = vetImage(vetsExpanded.find((v) => v.id === option.value))
              return (
                <div className="position-relative d-flex align-items-center justify-content-start">
                  {imageSrc && <img className="mr-2 rounded rounded-circle height-25px width-25px" src={imageSrc} />}

                  <p className="text-m m-0">{option.label}</p>
                </div>
              )
            }}
          />

          {isVetForMultipleEnterprises && (
            <Select
              classNamePrefix="react-select"
              className="my-2 max-width-350px w-100"
              placeholder="Filter enterprise"
              onChange={(o: Option | null) => setSelectedEnterprise(o)}
              options={enterpriseOptions}
              value={selectedEnterprise}
              isClearable
              formatOptionLabel={(option: any) => (
                <div className="position-relative d-flex align-items-center justify-content-start">
                  {option.image && <img className="pr-2 max-height-25px max-width-70px w-auto h-auto" src={option.image} />}

                  <p className="text-m m-0">{option.label}</p>
                </div>
              )}
            />
          )}
        </div>

        {isQuerying[QueryName.CompletedConsultations] ? (
          <Spinner className="my-3" color="primary" />
        ) : (
          <CompletedTable
            total={
              completedConsultationsFiltered.length > 0 && (isAdmin(role) || selectedVet?.value === user?.id)
                ? sumBy(completedConsultationsFiltered, (c) => c.receiving_vet_pay_amount)
                    .toFixed(2)
                    .toLocaleString()
                : undefined
            }
            completedConsultations={completedConsultationsFiltered}
            distributionEmails={distributionEmails}
            fetchCompletedConsultations={fetchCompletedConsultations}
            selectedVet={selectedVet}
          />
        )}

        {isAdmin(role) && (
          <div>
            <Button outline className="my-2 d-block" onClick={handleDownload} disabled={!completedConsultations.length}>
              Download
            </Button>

            <Button outline className="my-2 d-block" onClick={handleMarkAllAsPaid} disabled={!completedConsultations.length}>
              Mark all as paid
            </Button>
          </div>
        )}
      </MainBox>
    </Layout>
  )
}
