import React, { useEffect, useState } from 'react'
import round from 'lodash/round'
import moment from 'moment'
import isNumber from 'lodash/isNumber'
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown, Button, Spinner } from 'reactstrap'
import { navigate } from 'gatsby-link'
import { useDispatch, useSelector } from 'react-redux'
import { useTable } from 'react-table'

import 'react-datepicker/dist/react-datepicker.css'

import { DistributionEmails_distribution_emails } from '../../hasura/graphQlQueries/types/DistributionEmails'
import { Input, Textarea } from '../common/Input'
import { NotificationId, setNotificationAction } from '../../hasura/slices/notifications'
import { Table, Header, HeaderCell, Cell, TextCell, NoResults, Row, isColumn } from '../common/Table'
import { consultation_addendums_insert_input } from '../../../types/globalTypes'
import { insertConsultationAddendumAction } from '../../hasura/slices/consultations'
import { usersSelector, UsersState } from '../../hasura/slices/users'

import {
  CompletedConsultation,
  updateChargeAction,
  updatePaidAction,
  downloadConsultationPdfAction,
} from '../../hasura/slices/consultations'

import {
  adminConsultationDescription,
  isAdmin,
  formatDollarAmount,
  isPositive,
  Option,
  vetNameWithoutQualifications,
} from '../../lib/helpers'

// @ts-ignore
import dots from '../../lib/images/dots.svg'

const defaultColumn: any = {
  TextCell,
}

type ChargeAdjustmentNotes = [id: number, notes: string]
type PriceAmount = [id: number, amount: string]

interface Props {
  fetchCompletedConsultations: (loading: boolean) => void
  selectedVet: Option | null
  selectedEnterpriseId?: number
  completedConsultations: CompletedConsultation[]
  distributionEmails: DistributionEmails_distribution_emails[]
  total?: string
}

export default function CompletedTable(props: Props) {
  const dispatch = useDispatch()

  const { accessToken, user, role }: UsersState = useSelector(usersSelector)

  const { completedConsultations } = props

  const [columns, setColumns] = useState<any[] | undefined>([])
  const [isUpdatingRow, setIsUpdatingRow] = useState(false)
  const [chargeAdjustmentNotes, setChargeAdjustmentNotes] = useState<ChargeAdjustmentNotes | undefined>()
  const [priceAmount, setPriceAmount] = useState<PriceAmount | undefined>()
  const [vetPayAmount, setVetPayAmount] = useState<PriceAmount | undefined>()

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    // @ts-ignore
    columns,
    data: completedConsultations,
    defaultColumn,
  })

  /*
    Methods
  */

  const handleSaveCharge = async () => {
    const id = chargeAdjustmentNotes?.[0]! || priceAmount?.[0] || vetPayAmount?.[0]
    const consultation = completedConsultations.find((c) => c.id === id)
    const parsedPriceAmount = priceAmount ? round(parseFloat(priceAmount?.[1]!), 2) : consultation?.price_amount
    const parsedVetPayAmount = vetPayAmount ? round(parseFloat(vetPayAmount?.[1]!), 2) : consultation?.receiving_vet_pay_amount
    if ((priceAmount && !isPositive(parsedPriceAmount)) || (vetPayAmount && !isPositive(parsedVetPayAmount))) {
      return dispatch(setNotificationAction(NotificationId.InvalidAmount))
    }

    const notes = chargeAdjustmentNotes ? chargeAdjustmentNotes?.[1]! : consultation?.charge_adjustment_notes
    setIsUpdatingRow(true)
    await dispatch(updateChargeAction(accessToken, id!, parsedPriceAmount, parsedVetPayAmount, notes || null))
    props.fetchCompletedConsultations(false)
  }

  const handleView = (id: number) => navigate(`/consultations?i=${id}`)

  const handleUpdatePaid = async (id: number, paid: boolean) => {
    await dispatch(updatePaidAction(accessToken, id, paid))
    props.fetchCompletedConsultations(false)
  }

  const resetColumns = (id: number) => {
    if (id !== chargeAdjustmentNotes?.[0]) setChargeAdjustmentNotes(undefined)
    if (id !== vetPayAmount?.[0]) setVetPayAmount(undefined)
    if (id !== priceAmount?.[0]) setPriceAmount(undefined)
  }

  const reset = () => {
    setIsUpdatingRow(false)
    setPriceAmount(undefined)
    setChargeAdjustmentNotes(undefined)
    setVetPayAmount(undefined)
  }

  /*
    Effects 
  */

  useEffect(() => {
    reset()
  }, [completedConsultations])

  useEffect(() => {
    setColumns(
      [
        isAdmin(role) && {
          Header: 'ID',
          accessor: (c: CompletedConsultation) => c.display_id,
        },
        {
          Header: 'Completed',
          accessor: (c: CompletedConsultation) => moment(c.completed_at).format('MM/DD/YYYY h:mmA'),
        },
        {
          Header: 'Description',
          accessor: (c: CompletedConsultation) => {
            let description = adminConsultationDescription(c)
            if (!isAdmin(role)) return description

            const emails = (c.distribution_email_ids_denormalized || '')
              .split(',')
              .map((id) => props.distributionEmails.find((d) => d.id === parseInt(id))?.email)
              .join(', ')
            if (emails.length) description += ` (${emails})`
            return description
          },
        },
        {
          Header: 'Specialist',
          accessor: (c: CompletedConsultation) => vetNameWithoutQualifications(c.receiving_vet?.display_name),
        },
        (isAdmin(role) || props.selectedVet?.value === user?.id) && {
          Header: 'Earnings',
          accessor: (c: CompletedConsultation) =>
            c.receiving_vet_pay_amount ? `$${formatDollarAmount(c.receiving_vet_pay_amount)}` : null,
        },
        isAdmin(role) && {
          Header: 'Paid',
          accessor: (c: CompletedConsultation) => c.receiving_vet_paid,
        },
        isAdmin(role) && {
          Header: 'Charge Notes',
          accessor: (c: CompletedConsultation) => c.charge_adjustment_notes,
        },
        isAdmin(role) && {
          Header: 'Charge Amount',
          accessor: (c: CompletedConsultation) => c.price_amount,
        },
        {
          Header: 'View',
        },
      ].filter((obj) => obj)
    )
  }, [props.selectedVet, role, props.distributionEmails])

  const colSpan = (data: any) => (isColumn(data, 'Description') || isColumn(data, 'Charge Notes') ? 2 : 1)

  return (
    <div className="border rounded p-4">
      <h5 className="bold">Consultations</h5>

      <Table cellSpacing={0} {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup: any, idx: number) => (
            <tr key={idx} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any) => {
                if (column.Header === 'View') {
                  return <HeaderCell key={idx} colSpan={2} />
                }

                return (
                  <HeaderCell key={idx} {...column.getHeaderProps()} colSpan={colSpan(column)}>
                    <Header>{column.render('Header')}</Header>
                  </HeaderCell>
                )
              })}
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps()}>
          {rows.length ? (
            rows.map((row: any, idx: number) => {
              prepareRow(row)

              const isPaid = row.original.receiving_vet_paid

              return (
                <Row key={idx} {...row.getRowProps()}>
                  {row.cells.map((cell: any, idx2: number) => {
                    const id = cell.row.original.id
                    const isEditingChargeAdjustmentNotes = chargeAdjustmentNotes?.[0] === id
                    const isEditingPriceAmount = priceAmount?.[0] === id
                    const isEditingVetPayAmount = vetPayAmount?.[0] === id

                    if (cell.column.Header === 'Charge Notes') {
                      return (
                        <Cell key={idx2} {...cell.getCellProps()} colSpan={2}>
                          {isEditingChargeAdjustmentNotes && isUpdatingRow ? (
                            <Spinner size="sm" color="primary" />
                          ) : (
                            <Textarea
                              onChange={(e) => {
                                resetColumns(id)
                                setChargeAdjustmentNotes([id, e.target.value])
                              }}
                              value={
                                (isEditingChargeAdjustmentNotes
                                  ? chargeAdjustmentNotes?.[1]
                                  : cell.row.original.charge_adjustment_notes) || ''
                              }
                            />
                          )}
                        </Cell>
                      )
                    } else if (cell.column.Header === 'Charge Amount') {
                      return (
                        <Cell key={idx2} {...cell.getCellProps()}>
                          {isEditingPriceAmount && isUpdatingRow ? (
                            <Spinner size="sm" color="primary" />
                          ) : (
                            <div className="d-flex align-items-center mr-3">
                              <span className="text-m">$</span>

                              <Input
                                className="ml-1"
                                onChange={(e: any) => {
                                  resetColumns(id)
                                  setPriceAmount([id, e.target.value])
                                }}
                                placeholder="Amount..."
                                value={
                                  isEditingPriceAmount
                                    ? priceAmount?.[1]
                                    : isNumber(cell.row.original.price_amount)
                                    ? cell.row.original.price_amount
                                    : ''
                                }
                              />
                            </div>
                          )}
                        </Cell>
                      )
                    } else if (cell.column.Header === 'Earnings' && isAdmin(role)) {
                      return (
                        <Cell key={idx2} {...cell.getCellProps()}>
                          {isEditingVetPayAmount && isUpdatingRow ? (
                            <Spinner size="sm" color="primary" />
                          ) : (
                            <div className="d-flex align-items-center mr-3">
                              <span className="text-m">$</span>

                              <Input
                                className="ml-1"
                                onChange={(e: any) => {
                                  resetColumns(id)
                                  setVetPayAmount([id, e.target.value])
                                }}
                                placeholder="Amount..."
                                value={
                                  isEditingVetPayAmount
                                    ? vetPayAmount?.[1]
                                    : isNumber(cell.row.original.receiving_vet_pay_amount)
                                    ? cell.row.original.receiving_vet_pay_amount
                                    : ''
                                }
                              />
                            </div>
                          )}
                        </Cell>
                      )
                    } else if (cell.column.Header === 'View') {
                      return (
                        <Cell key={idx2} {...cell.getCellProps()} colSpan={2} className="position-relative">
                          <div className="d-flex align-items-center justify-content-start">
                            {(isEditingChargeAdjustmentNotes || isEditingPriceAmount || isEditingVetPayAmount) && (
                              <Button className="mr-2" color="success" onClick={handleSaveCharge}>
                                Save
                              </Button>
                            )}
                            <Button className="mr-2" onClick={() => handleView(id)} color="primary">
                              View
                            </Button>

                            <UncontrolledDropdown className="dropdown-menu-dots" onClick={(e: any) => e.stopPropagation()}>
                              <DropdownToggle caret>
                                <img className="icon-s ml-2 mb-1" src={dots} />
                              </DropdownToggle>

                              <DropdownMenu right>
                                <DropdownItem header>
                                  <p className="m-0 text-s text--gray8">More actions</p>
                                </DropdownItem>

                                {isAdmin(role) && (
                                  <DropdownItem text>
                                    <p
                                      onClick={async () => {
                                        const sending_vet_text = window.prompt(
                                          "Please enter the practice's question for the radiologist:"
                                        )
                                        if (!sending_vet_text) return

                                        const object: consultation_addendums_insert_input = {
                                          consultation_id: cell.row.original.id,
                                          receiving_vet_id: cell.row.original.receiving_vet.id,
                                          sending_vet_text,
                                        }
                                        await dispatch(insertConsultationAddendumAction(accessToken, object))
                                        dispatch(setNotificationAction(NotificationId.AddendumCreated))
                                        await props.fetchCompletedConsultations(false)
                                      }}
                                      className="m-0 text-s regular pointer"
                                    >
                                      Create addendum
                                    </p>
                                  </DropdownItem>
                                )}

                                <DropdownItem text>
                                  <p
                                    onClick={() => dispatch(downloadConsultationPdfAction(cell.row.original.s3_url))}
                                    className="m-0 text-s regular pointer"
                                  >
                                    Download PDF
                                  </p>
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </div>

                          {cell.row.original.addendums.length > 0 && (
                            <p className="text-xs text--danger semibold position-absolute w-100 text-right mb-0 pr-3">
                              Waiting for addendum
                            </p>
                          )}
                        </Cell>
                      )
                    } else if (cell.column.Header === 'Paid') {
                      return (
                        <Cell key={idx2} {...cell.getCellProps()}>
                          <input onChange={() => handleUpdatePaid(id, !isPaid)} checked={isPaid} type="checkbox" />
                        </Cell>
                      )
                    } else {
                      return (
                        <Cell colSpan={colSpan(cell.column)} key={idx2} {...cell.getCellProps()}>
                          {cell.render('Cell')}
                        </Cell>
                      )
                    }
                  })}
                </Row>
              )
            })
          ) : (
            <NoResults columns={columns?.length} message="No consultations." />
          )}

          {props.total && (
            <tr>
              <Cell />
              <Cell colSpan={2} />
              <Cell />
              <Cell>
                <p className="mb-0 text-l">
                  <span className="bold mr-2">TOTAL</span>${props.total}
                </p>
              </Cell>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  )
}
